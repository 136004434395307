@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Other imports
@import "include/cookieconsent";
@import "include/full-calendar";
@import "include/extended-utils";
@import "include/spotler-forms";
@import "~hamburgers/_sass/hamburgers/hamburgers";
@import "~daterangepicker";
@import "vue-select/dist/vue-select.css";

// Import BootstrapVue
@import "~bootstrap-vue/src/variables";
@import "~bootstrap-vue/src/utilities";
@import "~bootstrap-vue/src/custom-controls";
@import "~bootstrap-vue/src/components/modal/index";
@import "~bootstrap-vue/src/components/table/index";
@import "~bootstrap-vue/src/components/pagination/index";
@import "~bootstrap-vue/src/components/skeleton/index";
@import "~bootstrap-vue/src/components/toast/index";
//@import '~bootstrap-vue/src';

$lg-path-fonts: '~lightgallery/fonts';
$lg-path-images: '~lightgallery/images';
@import "~lightgallery/scss/lightgallery";
@import "~lightgallery/scss/lg-video";

$account-button-style: 'outline-light' !default;
$booking-button-style: 'outline-success' !default;
$order-button-style: 'outline-primary' !default;

$owl-image-path: '~owl.carousel/src/img/';
@import "~owl.carousel/src/scss/owl.carousel";

@import 'components/video-light-gallery.scss';

$ticket-style: blocks !default;  // blocks, list
$activity-page-style: sidebar !default; // sidebar, no-sidebar

$language-selector-btn-variant: outline-secondary !default; // language selector button

.card-columns {
  @include media-breakpoint-only(lg) {
    column-count: 2;
  }
  @include media-breakpoint-only(md) {
    column-count: 1;
  }
  @include media-breakpoint-only(sm) {
    column-count: 1;
  }
}

.navbar-nav, .dropdown-submenu {
  .dropdown-toggle::after {
    display: none;
  }
}

dt.col.minimal {
  max-width: 1px;
  padding-right: 10px;
}

.hamburger {
  position: relative;
  .badge {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    transition: opacity 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  &.is-active {
    .badge {
      //display: none;
      opacity: 0;
    }
  }
}

.account-dropdown--style {
  padding: 0.5rem 0;
}


.border-left-line-secondary {
  border-left: $secondary solid 4px;
}

.border-left-line-success {
  border-left: $success solid 4px;
}

.border-left-line-warning {
  border-left: $warning solid 4px;
}

.border-left-line-primary {
  border-left: $primary solid 4px;
}

.border-left-line-info {
  border-left: $info solid 4px;
}

#daterangeinput {
  border-top-left-radius: $input-border-radius;
  border-bottom-left-radius: $input-border-radius;
}

.required-tag {
  @extend .badge;
  background-color: $white;
  border: solid 1px #666;
  font-weight: 400;
  border-radius: $border-radius;
  color: #666;
  font-size: 85%;
}

.nav-cart {
  @extend .position-relative;
  .nav-link {
    @extend .px-3;
    &.disabled {
      .badge {
        display: none;
      }
    }
  }
  .badge {
    @extend .ml-2, .ml-lg-0;
    position: absolute;
    top: 0;
    right: 0;
  }
  small {
    .badge {
      top: -2px !important;
      right: -7px !important;
    }
  }
}

.dropdown-menu {
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.importantInput {
  display: none;
}

.grecaptcha-badge { visibility: hidden; }

.container img {
  max-width: 100%;
  //&:not([class]) {
  //  @include media-breakpoint-down(sm) {
  //    width: 100%;
  //  }
  //}
}

.contact--warehouse {
  .warehouse--item:last-child {
    hr {
      display: none;
    }
  }
}

.modal-dialog-scrollable {
  max-height: subtract(100%, $modal-dialog-margin * 10);
  .modal-content {
    max-height: subtract(100vh, $modal-dialog-margin * 10); // IE10/11
  }
  .modal-footer {
    position: sticky;
    bottom: 0;
    background-color: white;
    @extend .z-1;
  }
}

#PersonForm-Insert, #Person_ActivityGroupForm-Insert {
  .custom-checkbox:has(input:disabled) {
    display: none;
  }
}

.wrapper--hideCheckbox {
  .insertform--hideCheckbox {
    .availability-select {
      .custom-checkbox {
        display: none;
      }
    }
  }
}

.has-user-content {
  img {
    height: auto !important;
  }

  // responsive prestyled tables on mobile
  table {
    height: auto !important;
    width: auto !important;
    table-layout:fixed;
    border: none;
    th,td {
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      border: 1px solid lighten($primary, 35%);
      padding: .5rem;
    }
    th {
      padding-top: .7rem;
      padding-bottom: .7rem;
      border-color: $primary !important;
      background-color: $primary !important;
      color: $white !important;
    }
    tr:nth-child(even) {
      background-color: $light;
    }
    tr:hover {
      background-color: darken($light, 3%);
    }
    @include media-breakpoint-down(md) {
      overflow-x: auto;
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    iframe {
      width: 100%;
    }
  }
}

.object-fit-contain {
  object-fit: contain !important;
}

.text-contrast {
  color: $theme-contrast-color;
}

.min-h-100 {
  min-height: 100%;
}

.text-legal, .opacity-5 {
  opacity: .5;
}

.account-button--style {
  @extend .btn-primary;
}

.my-menu-head {
  @extend .list-group-item-primary;
}

.mobile-hamburger-color {
  color: $dark;
}

.rental-scheduler {
  .leaflet-control-attribution {
    font-size: 7px !important;
  }
  .fc-event-main-frame {
    flex-direction: row !important;
  }
  .fc-event-time {
    font-size: inherit !important;
    @extend .mr-1;
  }
}

.account--button-wrapper {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.upsell {
  .upsell-row {
    .upsell-item-wrapper {
      .age-badge--md {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      &:first-child:not(:last-child) {
        .ticket-list-item--inner {
          @include media-breakpoint-down(md) {
            border-bottom-left-radius: 0;
            border-bottom: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
      &:nth-child(2):not(:last-child) {
        .ticket-list-item--inner {
          @include media-breakpoint-down(md) {
            border-radius: 0;
            border-bottom: 0;
          }
        }
      }
      &:last-child:not(:first-child) {
        .ticket-list-item--inner {
          @include media-breakpoint-down(md) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }
  }
  .owl-carousel {
    .owl-nav {
      @extend .w-100, .d-flex, .justify-content-between, .mt-2;
      .disabled {
        .btn-link {
          display: none;
        }
      }
    }
  }
}

.footer-menu--item {
  font-weight: 600;
}

.account--button {
  @extend .btn-#{$account-button-style};
}

.btn-booking {
  @extend .btn-#{$booking-button-style};
}

.btn-order{
  @extend .btn-#{$order-button-style};
}

.minimal {
  max-width: 1px !important;
  padding-right: 10px;
}

.info-bar--background {
  padding: 0.5rem 1.5rem;
  @extend .text-white;
}

.btn--language-selector-variant {
  @extend .btn-#{ $language-selector-btn-variant };
}

.vs__dropdown-toggle {
  border-radius: $border-radius;
}

.InAcademia-explainer {
  p {
    margin-bottom: 0;
  }
}

@import "include/a11y";
@import "components/psp-selector";
@import "components/timePick-selector";
@import "include/accordion";
@import "include/page/activity";
@import "include/ticket/layouts";
